<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row
        class="flex-no__wrap ml-auto wanted-search-input-wrapper"
        type="flex"
        style="justify-content: flex-end"
      />
    </a-row>
    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :data-source="tableProps"
    >
      <template slot="name" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: item.name,
              params: {
                id: item.id,
                type: item.type
              }
            })
          "
        >
          {{ item.title[$i18n.locale] }}
        </div>
      </template>
    </a-table>
  </a-row>
</template>

<script>
// import searchInput from "@/components/wanted/search-input"

const columns = [
  {
    title: "Название",
    key: "title",
    class: "wanted-fio-td",
    scopedSlots: {
      customRender: "name"
    }
  }
]

export default {
  data: () => ({
    tableProps: [
      {
        title: {
          oz: "Вакансиялар",
          uz: "Vakansiyalar",
          ru: "Вакансии",
          en: "Vacancies"
        },
        name: "vacs"
      },
      {
        title: {
          oz: "Тайинлов",
          uz: "Tayinlov",
          ru: "Номинация",
          en: "Nomination"
        },
        name: "posts",
        id: 47
        // id: 42
      },
      {
        title: {
          oz: "Молия вазирлигидаги фаолияти",
          uz: "Moliya vazirligidagi faoliyati",
          ru: "Карьера в министерстве финансов",
          en: "Сareer in finance ministry"
        },
        name: "posts",
        id: 49
        // id: 43
      },
      {
        title: {
          oz: "Вакансия учун департаментлар",
          uz: "Vakansiya uchun departamentlar",
          qr: "Департаменты для вакансии",
          ru: "Департаменты для вакансии",
          en: "Departments for vacancy"
        },
        name: "vacDepart"
      }
    ],
    columns
  }),
  methods: {}
}
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
/*.ministry .ant-table-pagination.ant-pagination {
  display: none;
}*/
</style>
